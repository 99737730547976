<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <img :src="m_dno + webp()" alt="" class="im-image" />
    </div>
    <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
      <div class="flex-fill d-flex">
        <div>
          <div class="im-title">Фальш-дно</div>
          <div class="im-subtitle">Фильтрация солода и многое другое</div>
          <div class="im-text">
            <p>
              Фальш-дно - это щелевое сито, устанавливающееся в в ёмкость. В
              приготовлении напитков оно выполняет 3 функции: 1) Фильтрация
              солода после варки пива. Солод - это пророщенное и смолотое зерно,
              и после варки в кубе, его надо отфильтровать. Без фальш-дна это
              можно сделать через марлевый мешок или мелкий дуршлаг, что не
              всегда удобно. 2) Перегонка густых браг с выжимками фруктов (чача)
              или солодом (виски по красной схеме). Гуща браги не касается дна и
              не пригорает. 3) Паровая ароматизация. Дистиллят (самогон) или
              ректификат (спирт) заливают до фальш-дна, кладут на него ароматные
              ингредиенты (сухофрукты, цедру, кофе, корицу, ваниль и т.д.) и
              перегоняют.
            </p>
            <p>
              Фальш-дно изготовлено из нержавеющей стали AISI 304 и благодаря
              ребру жёсткости выдерживает нагрузку до 50 кг. Диаметр 360 мм.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center im-delivery-container">
        <div class="col-auto">
          <img :src="car" alt="" class="im-delivery-icon" />
        </div>
        <div class="col col-md-8 im-delivery-text">
          Доставка при заказе с аппаратом DOMSPIRT бесплатная.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const m_dno = require("@/pages/Main/assets/modal/m_dno.png");
const car = require("@/pages/Main/assets/car.svg");
import webp from "@/functions/webp";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "html",
  data: function () {
    return {
      webp,
      m_dno,
      car,
    };
  },
};
</script>

<style scoped></style>
